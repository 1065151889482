import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import React from "react";
import numeral from "numeral";

const LabourProgress = ({ Data, JobData }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 21,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      width: "108px",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#4318ff",
      ...theme.applyStyles("dark", {
        backgroundColor: "#eff4fb",
      }),
    },
  }));

  const Test = ({ estimated, actual }) => {
    const progress = Math.min(Math.round((actual / estimated) * 100), 100);
    const exceeded =
      actual > estimated
        ? Math.round(((actual - estimated) / estimated) * 100)
        : 0;

    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className={`progress-bar-fill ${
              actual > estimated ? "exceeded" : ""
            }`}
            style={{ width: `${progress}%` }}
          >
            <span className="progress-bar-label">{progress}%</span>
          </div>
          {actual > estimated && (
            <div
              className="progress-bar-exceeded"
              style={{ width: `${exceeded}%` }}
            >
              <span className="progress-bar-label">{100 + exceeded}%</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="QuoteSheetKpi CardOne TableCard">
      <div className="CardHead">Labour Insights</div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Actual</th>
            <th>Estimated</th>
            <th>Budget o/u or lost/gain</th>
            {/* <th>Progress</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Labour hour</td>
            <td>{JobData.labour_hours ? JobData.labour_hours : "0"}</td>
            <td>
              {Data.est_labour_hours_total ? Data.est_labour_hours_total : "0"}
            </td>
            <td>
              {Data.est_labour_hours_over_under_percent
                ? `${Data.est_labour_hours_over_under_percent}%`
                : "0"}
            </td>
            {/* <td>
              {Data.est_labour_hours_total && JobData.labour_hours ? (
                <Test
                  estimated={Data.est_labour_hours_total}
                  actual={JobData.labour_hours}
                />
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </td> */}
          </tr>
          <tr>
            <td>Labour days</td>
            <td>
              {JobData.labour_hours
                ? (JobData.labour_hours / 8).toFixed(0)
                : "0"}
            </td>
            <td>
              {Data.est_labour_days_total ? Data.est_labour_days_total : "0"}
            </td>
            <td>0</td>
            {/* <td>
              {Data.est_labour_days_total && JobData.labour_hours ? (
                <Test
                  estimated={Data.est_labour_days_total}
                  actual={JobData.labour_hours / 8}
                />
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </td> */}
          </tr>
          <tr>
            <td>Labour CTC</td>
            <td>
              {Data.ctc_labour_actual
                ? `$${numeral(Data.ctc_labour_actual).format(0, 0)}`
                : "0"}
            </td>
            <td>
              {Data.ctc_labour_est
                ? `$${numeral(Data.ctc_labour_est).format(0, 0)}`
                : "0"}
            </td>
            <td>
              {Data.ctc_labour_over_under
                ? `$${numeral(Data.ctc_labour_over_under).format(0, 0)}`
                : "0"}
            </td>
            {/* <td>
              {Data.ctc_labour_est && Data.ctc_labour_actual ? (
                <Test
                  estimated={Data.ctc_labour_est}
                  actual={Data.ctc_labour_actual}
                />
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </td> */}
          </tr>
          <tr>
            <td>Revenue contract</td>
            <td>
              {Data.revenue_contract_labour
                ? `$${numeral(Data.revenue_contract_labour).format(0, 0)}`
                : "0"}
            </td>
            <td>
              {Data.revenue_potential_labour
                ? `$${numeral(Data.revenue_potential_labour).format(0, 0)}`
                : "0"}
            </td>
            <td>
              {Data.revenue_lost_gain_labour
                ? `$${numeral(Data.revenue_lost_gain_labour).format(0, 0)}`
                : "0"}
            </td>
            {/* <td>
              {Data.revenue_potential_labour && Data.revenue_contract_labour ? (
                <Test
                  estimated={Data.revenue_potential_labour}
                  actual={Data.revenue_contract_labour}
                />
              ) : (
                <div className="ItemHead">No Data</div>
              )}
            </td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LabourProgress;
