import React, { useEffect, useState } from "react";
import UserOne from "../../assets/images/user-icon.svg";
import {
  GetProfile,
  ResetSuccessUpdate,
  UpdateProfile,
} from "../../reducers/AllApis";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress, LinearProgress } from "@mui/material";

const Profile = () => {
  const dispatch = useDispatch();
  const Data = localStorage.getItem("ProfileData");
  const [Loading, setLoading] = useState(false);
  const ProfileData = Data ? JSON.parse(Data) : [];

  const [initialFormData] = useState({
    first_name:
      ProfileData?.first_name !== "null" ? ProfileData.first_name : "",
    last_name: ProfileData?.last_name !== "null" ? ProfileData.last_name : "",
    email: ProfileData?.email !== "null" ? ProfileData.email : "",
    contact: ProfileData?.contact !== "null" ? ProfileData.contact : "",
    country: ProfileData?.country !== "null" ? ProfileData.country : "",
    state: ProfileData?.state !== "null" ? ProfileData.state : "",
    city: ProfileData?.city !== "null" ? ProfileData.city : "",
    profile: ProfileData?.profile !== "null" ? ProfileData.profile : "",
  });

  const [formDataMain, setformDataMain] = useState(initialFormData);
  const [isChanged, setIsChanged] = useState(false);
  const [changedFields, setChangedFields] = useState({});

  const { UpdateProfileSuccess } = useSelector((state) => state.allapi);



  const handleChange = (e) => {
    const { name, value } = e.target;

    setformDataMain({
      ...formDataMain,
      [name]: value,
    });

    if (value !== ProfileData[name]) {
      setChangedFields((prev) => ({
        ...prev,
        [name]: value,
      }));
      setIsChanged(true);
    } else {
      const { [name]: removed, ...rest } = changedFields;
      setChangedFields(rest);
      setIsChanged(Object.keys(rest).length > 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.entries(changedFields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    dispatch(UpdateProfile(formData));
    console.log("Changed Fields:", changedFields);
  };

  useEffect(() => {
    dispatch(GetProfile());
    if (UpdateProfileSuccess === true) {
      setLoading(false);
      setIsChanged(false);
      dispatch(ResetSuccessUpdate());
    } else if (UpdateProfileSuccess === "Failed") {
      setIsChanged(false);
      setLoading(false);
      dispatch(ResetSuccessUpdate());
    } else if (UpdateProfileSuccess === "Pending") {
      setLoading(true);
    }
  }, [UpdateProfileSuccess]);

  useEffect(() => {
    localStorage.setItem("ProfileData", JSON.stringify(ProfileData));
  }, [ProfileData]);

  const handleCancel = () => {
    setformDataMain(initialFormData);
    setIsChanged(false);
    setChangedFields({});
  };

  const handleChangeProfile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile", file);
      console.log(file);
      dispatch(UpdateProfile(formData));
    }
  };

  return (
    <div className="ProfileEditPage">
      <Toaster />
      <div className="EditImage">
        <img
          className="ProfileImage"
          src={ProfileData?.profile ? ProfileData?.profile : UserOne}
        />
        <div className="EditButton">
          <input type="file" onChange={handleChangeProfile} />
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="15" r="15" fill="#1814F3" />
            <g clipPath="url(#clip0_1_9971)">
              <path
                d="M22.5872 11.1633L21.2366 12.5139C21.0989 12.6516 20.8763 12.6516 20.7386 12.5139L17.4866 9.26196C17.3489 9.12427 17.3489 8.90161 17.4866 8.76392L18.8372 7.41333C19.385 6.86548 20.2757 6.86548 20.8265 7.41333L22.5872 9.17407C23.138 9.72192 23.138 10.6125 22.5872 11.1633ZM16.3265 9.92407L8.63309 17.6174L8.012 21.177C7.92704 21.6575 8.34599 22.0735 8.82645 21.9915L12.386 21.3674L20.0794 13.6741C20.2171 13.5364 20.2171 13.3137 20.0794 13.176L16.8274 9.92407C16.6868 9.78638 16.4642 9.78638 16.3265 9.92407ZM11.636 16.9583C11.4749 16.7971 11.4749 16.5393 11.636 16.3782L16.1477 11.8665C16.3089 11.7053 16.5667 11.7053 16.7278 11.8665C16.889 12.0276 16.889 12.2854 16.7278 12.4465L12.2161 16.9583C12.055 17.1194 11.7972 17.1194 11.636 16.9583ZM10.5784 19.4221H11.9847V20.4856L10.095 20.8167L9.18388 19.9055L9.51493 18.0159H10.5784V19.4221Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_9971">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(8 7)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="EditDet">
        <form>
          <div className="FormSec">
            <div className="InputDiv">
              <div className="Label">First name</div>
              <input
                type="text"
                name="first_name"
                value={formDataMain.first_name}
                onChange={handleChange}
                placeholder="Enter your first name"
              />
            </div>
            <div className="InputDiv">
              <div className="Label">Last name</div>
              <input
                type="text"
                name="last_name"
                value={formDataMain.last_name}
                onChange={handleChange}
                placeholder="Enter your last name"
              />
            </div>
          </div>

          <div className="FormSec">
            <div className="InputDiv">
              <div className="Label">Email</div>
              <input
                type="email"
                name="email"
                value={formDataMain.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </div>
            <div className="InputDiv">
              <div className="Label">Contact</div>
              <input
                type="number"
                name="contact"
                value={formDataMain.contact}
                onChange={handleChange}
                placeholder="Enter your contact"
              />
            </div>
          </div>

          <div className="FormSec">
            <div className="InputDiv">
              <div className="Label">Country</div>
              <input
                type="text"
                name="country"
                value={formDataMain.country}
                onChange={handleChange}
                placeholder="Enter your country"
              />
            </div>
            <div className="InputDiv">
              <div className="Label">City</div>
              <input
                type="text"
                name="city"
                value={formDataMain.city}
                onChange={handleChange}
                placeholder="Enter your city"
              />
            </div>
          </div>

          <div className="FormSec justify-end">
            <button
              type="submit"
              className="ButtonPrimary"
              disabled={!isChanged}
              style={!isChanged ? { opacity: "0.5" } : { opacity: "1" }}
              onClick={handleSubmit}
            >
              {Loading === true ? <CircularProgress size="1rem" /> : "Submit"}
            </button>

            <button
              type="button"
              className="ButtonCancel"
              disabled={!isChanged}
              style={!isChanged ? { opacity: "0.5" } : { opacity: "1" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
