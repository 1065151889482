import numeral from "numeral";
import React from "react";

const OtherInputTable = ({Data}) => {

  return (
    <div className="QuoteSheetKpi CardOne TableCard">
      <div className="CardHead">Other Costs</div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Total Costs</th>
            <th>Charge Out Amount</th>
         
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Other Charges</td>
            <td>{Data.other_charges_total_costs ? `$${numeral(Data.other_charges_total_costs).format(0,0)}` : 0}</td>
            <td>{Data.other_charges_charge_out ? `$${numeral(Data.other_charges_charge_out).format(0,0)}` : 0}</td>

          </tr>
      
        </tbody>
      </table>
    </div>
  );
};


export default OtherInputTable;


