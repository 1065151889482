import React, { useEffect } from 'react'
import { ReportCSV } from '../reducers/AllApis';
import { useDispatch, useSelector } from 'react-redux';
import numeral from 'numeral';

const Reports = () => {


  const dispatch = useDispatch();
  const { ReportData } = useSelector((state) => state.allapi);

  useEffect(() => {
    dispatch(ReportCSV());
  }, []);



  return (
    <div className="QuoteSheetKpi CardOne TableCard">
    <div className="CardHead">
      Reports
    </div>
    <table>
      <thead>
        <tr>
          <th>% AC GP</th>
          <th>Job number</th>
          <th>Site adress</th>
          <th>Client</th>
          <th>Est Rev $</th>
          <th>Actual Margin $</th>
          <th>Est. Margin $</th>
          <th>Est. Budget $</th>
          <th>Actual $ CTC</th>
          <th>Budget (Over / U)</th>
        </tr>
      </thead>
      <tbody>
        {ReportData &&
          ReportData.map((x, i) => {
            const GrossPer = (x.total_est_margin / x.total_revenue) * 100

              return (
              <tr key={i}>
                <td>{GrossPer ? `${GrossPer.toFixed(0)}%` : 0}</td>
                <td>{x.job_id ? x.job_id : 0}</td>
                <td>{x.site_name ? x.site_name : "-"}</td>
                <td>{x.client_firm ? x.client_firm : "-"}</td>
                <td>{x.total_revenue ? numeral(x.total_revenue).format(0,0)  : 0}</td>
                <td>{x.total_actual_margin ? numeral(x.total_actual_margin).format(0,0) : 0}</td>
                <td>{x.total_est_margin ?  numeral(x.total_est_margin).format(0,0) : 0}</td>
                <td>{x.total_est_budget ?  numeral(x.total_est_budget).format(0,0) : 0}</td>
                <td>{x.total_actual_ctc ?  numeral(x.total_actual_ctc).format(0,0) : 0}</td>
                <td>{x.total_budget_over_under ? `${x.total_budget_over_under}%` : 0}</td>
        
                
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
  )
}

export default Reports