import React from 'react'
import Jobs from '../pages/Jobs';
import Reports from '../pages/Reports';
import SignIn from '../pages/Authentication/SignIn';
import JobDetails from '../pages/Details';
import Settings from '../pages/Settings';

const coreRoutes = [
    {
      path: '/jobs',
      title: 'Job list',
      component: Jobs,
    },
    {
        path: '/reports',
        title: 'Reports',
        component: Reports,
      },
      // {
      //   path: '/',
      //   title: 'SignIn',
      //   component: SignIn,
      // },
      {
        path: '/jobs/:value',
        title: 'Details',
        component: JobDetails,
      },
      {
        path: '/settings',
        title: 'Settings',
        component: Settings,
      },
  ];

  
  const routes = [...coreRoutes];
  export default routes;