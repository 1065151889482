import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { login } from "../../reducers/Auth";
import { GetProfile } from "../../reducers/AllApis";
import { toggleTheme } from "../../reducers/AllApis";
import { toast } from 'react-hot-toast';
import sunlight from "../../assets/images/sun icon.png";
import moonlight from "../../assets/images/moon-light.svg";
import ShowPass from "../../assets/images/Show-Pass.png"
import HidePass from "../../assets/images/Hide-Pass.png"

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [Err, setErr] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  
  const { Login_Success, Error, Login_Pending } = useSelector((state) => state.auth);

  const { theme } = useSelector((state) => state.allapi);
  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };
  

  const handleSubmit = () => {
    const credentials = { email: Email, password: Password };
    if (Email === '' || Password === '') {
      setErr(true);
      toast.error('Please fill all fields', { position: 'top-center' });
    } else {
      dispatch(login(credentials));
    }
  };

  useEffect(() => {

    if (Login_Success === true) {
      dispatch(GetProfile());
      navigate('/jobs');
    } else if (Login_Success === false && Error) {
      if (Error.includes('500')) {
        toast.error('Invalid login credentials', { position: 'top-center' });
      } else if (Error.includes('400')) {
        toast.error('User not registered', { position: 'top-center' });
      } else {
        toast.error('Something went wrong', { position: 'top-center' });
      }
    }
  }, [Login_Success, Error, dispatch, navigate]);

  return (
    <>
      <div class="loginhero">
        <div class="login-container">
          <div class="login-left items-center">
            
            <form>
              <h2>Sign In</h2>
              <p>Enter your email and password to sign in!</p>
              <label className="loginlabel">Email*</label>
              <div className="relative">
                <input 
                  value={Email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  type="email" 
                  placeholder="mail@simmmple.com"/>
                  {Email === '' && Err === true && (
                      <div style={{color:"red"}}>Please fill this field</div>
                    )}
                    
              </div>
              <label className="loginlabel">Password*</label>
              <div className="relative">
                <input
                 
                  value={Password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  type={ShowPassword ? "text" : "password"} 
                  placeholder="8+ Characters"/>
                    {Password === '' && Err === true && (
                      <div style={{color:"red"}}>Please fill this field</div>
                    )}
                <span className="ShowPass">
                    <img style={{width: "30px"}} onClick={() => setShowPassword(!ShowPassword)} src={ShowPassword? ShowPass : HidePass} />
                </span>    
              </div>
              <div className="submit-button" onClick={handleSubmit}>
                <a>
                  {Login_Pending ? <CircularProgress color="inherit" /> : "Sign In"}
                </a>
              </div>
            </form>
          </div>

          <div class="login-right">
            <h1 className="logoName">Quotech</h1>  
          </div>
          <div className="themelogologin">
          <div onClick={handleThemeToggle} className="themelogin">
            <img style={{width: "20px"}} src={theme == "dark" ? moonlight: sunlight}/>
          </div>
          </div>
          {/* <img className="themelogologin" onClick={handleThemeToggle} src={backgoundimg}/> */}
  </div>
</div>

    </>
  );
};

export default SignIn;
