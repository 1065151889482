import numeral from "numeral";
import React, { useState } from "react";

const PrimaryQuoteSheet = ({ Data }) => {
  const [openRows, setOpenRows] = useState({});

  const handleToggle = (rowId) => {
  
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId], 
    }));
  };
    const paragraphStyles = {
      WebkitLineClamp:1,
      WebkitBoxOrient:"vertical",
      overflow:"hidden",
      display:"-webkit-box"
    };
  
    const TableElHeight = {
      height: "60px"
    }
  
    const [columnGroups, setColumnGroups] = useState({
      group1: true,
      group2: true,
    });
  
    const toggleGroup = (group) => {
      setColumnGroups((prevGroups) => ({
        ...prevGroups,
        [group]: !prevGroups[group],
      }));
    };
  
  return (
    <div className="QuoteSheetKpi CardOne">
      <table>
        <thead>
          <tr>
            <th>Scope #</th>
            <th>Work Location</th>
            <th className="ItemLong">Descripton</th>
            <th className="ItemLong">Product / System</th>
            <th>Work Area</th>
            <th>Total Revenue $</th>
            <th>Est. Labour Days</th>
            <th>Est. Labour Hours</th>
            <th className="TextHeaderRed">CTC Labour $</th>
            <th>Rev. Labour $</th>
            <th className="TextHeaderRed">CTC Products $</th>
            <th>Rev. Product $</th>
            <th className="TextHeaderRed">CTC PP&E $</th>
            <th>Rev. PP&E $</th>
            <th className="TextHeaderRed">CTC Sub-Cont $</th>
            <th>Rev. Sub-Cont $</th>
          </tr>
        </thead>
        <tbody>
          {Data ?
            Data.map((x, i) => {
              return (
                <tr key={i} style={openRows[x.item] ? null : TableElHeight}>
                  <td>{x.item}</td>
                  <td>{x.work_location}</td>
                  <td>
                    <span style={openRows[x.item] ? null : paragraphStyles}>
                      {x.description}
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleToggle(x.item)}
                    >
                      {openRows[x.item] ? "Read Less.." : "Read More.."}
                    </span>
                  </td>
                  <td>{x.product_or_system}</td>
                  <td>{x.work_area}</td>
                  <td>{numeral(x.total_revenue).format(0, 0)}</td>
                  <td>{x.est_labour_days}</td>
                  <td>{x.est_labour_hours}</td>
                  <td>{numeral(x.ctc_labour).format(0, 0)}</td>
                  <td>{numeral(x.rev_labour).format(0, 0)}</td>
                  <td>{numeral(x.ctc_product).format(0, 0)}</td>
                  <td>{numeral(x.rev_product).format(0, 0)}</td>
                  <td>{numeral(x.ctc_ppe).format(0, 0)}</td>
                  <td>{numeral(x.rev_ppe).format(0, 0)}</td>
                  <td>{numeral(x.ctc_sub_cont).format(0, 0)}</td>
                  <td>{numeral(x.rev_sub_cont).format(0, 0)}</td>
                </tr>
              );
            }) : <div className="ItemSub" style={{padding: "20px"}}>No Data</div>}
        </tbody>
      </table>
    </div>
  );
};

export default PrimaryQuoteSheet;
