import React from "react";

const Test = ({ estimated, actual }) => {
  const progress = Math.min(Math.round((actual / estimated) * 100), 100);
  const exceeded = actual > estimated ? Math.round(((actual - estimated) / estimated) * 100) : 0;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className={`progress-bar-fill ${actual > estimated ? "exceeded" : ""}`}
          style={{ width: `${progress}%` }}
        >
          <span className="progress-bar-label">{progress}%</span>
        </div>
        {actual > estimated && (
          <div className="progress-bar-exceeded" style={{ width: `${exceeded}%` }}>
            <span className="progress-bar-label">{100 + exceeded}%</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Test;
