import numeral from "numeral";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const QuoteSheetChart = ({ QuoteData, TotalEstRev, TotalActualCTC }) => {
  const [chartState, setChartState] = useState(null);

  useEffect(() => {
    if (QuoteData && TotalEstRev > 0 && TotalActualCTC > 0) {
      const LabourPer = (QuoteData.labour_est_revenue / TotalEstRev) * 100;
      const ProductPer = (QuoteData.product_est_revenue / TotalEstRev) * 100;
      const PPEPer =
        (QuoteData.plant_rental_and_equip_est_revenue / TotalEstRev) * 100;
      const SubConPer =
        (QuoteData.sub_contract_est_revenue / TotalEstRev) * 100;
      const OtherPer =
        (QuoteData.other_charges_est_revenue / TotalEstRev) * 100;

      const LabourCTCPer = (QuoteData.labour_actual_ctc / TotalActualCTC) * 100;
      const ProductCTCPer =
        (QuoteData.product_actual_ctc / TotalActualCTC) * 100;
      const PPECTCPer =
        (QuoteData.plant_rental_and_equip_actual_ctc / TotalActualCTC) * 100;
      const SubConCTCPer =
        (QuoteData.sub_contract_actual_ctc / TotalActualCTC) * 100;
      const OtherCTCPer =
        (QuoteData.other_charges_actual_ctc / TotalActualCTC) * 100;

      setChartState({
        series: [
          {
            name: "Labour",
            data: [LabourPer.toFixed(0), LabourCTCPer.toFixed(0)],
          },
          {
            name: "Product",
            data: [ProductPer.toFixed(0), ProductCTCPer.toFixed(0)],
          },
          { name: "PPE", data: [PPEPer.toFixed(0), PPECTCPer.toFixed(0)] },
          {
            name: "Sub Contractor",
            data: [SubConPer.toFixed(0), SubConCTCPer.toFixed(0)],
          },
          {
            name: "Other",
            data: [OtherPer.toFixed(0), OtherCTCPer.toFixed(0)],
          },
        ],
        options: {
          tooltip: {
            y: {
              formatter: function (value, { seriesIndex, dataPointIndex }) {
                const originalValues = [
                  [QuoteData.labour_est_revenue, QuoteData.labour_actual_ctc],
                  [QuoteData.product_est_revenue, QuoteData.product_actual_ctc],
                  [
                    QuoteData.plant_rental_and_equip_est_revenue,
                    QuoteData.plant_rental_and_equip_actual_ctc,
                  ],
                  [
                    QuoteData.sub_contract_est_revenue,
                    QuoteData.sub_contract_actual_ctc,
                  ],
                  [
                    QuoteData.other_charges_est_revenue,
                    QuoteData.other_charges_actual_ctc,
                  ],
                ];
                const originalValue = numeral(
                  originalValues[seriesIndex][dataPointIndex]
                ).format("0,0");
                return `${value}% ($${originalValue})`;
              },
            },
          },
          dataLabels: {
            enabled: true,

            formatter: function (val, opt) {
              return val.toFixed(0) + "%";
            },
          },
          chart: {
            type: "bar",

            stacked: true,
            toolbar: { show: false },
            zoom: { enabled: true },
            stackType: "100%",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
            },
            {
              breakpoint: 400,
              options: {
                legend: {
                  width: "300px",
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10, // Adjust the value as needed
              borderRadiusApplication: 'both', // Apply border radius to both ends
            },
          },
          colors: ["#81BAE7", "#A9DEB6", "#F5F18D", "#F3BF71", "#E98AA7"],
          xaxis: {
            type: "category",
            categories: ["Estimated", "Actual"],
          },
          legend: {
            position: "right",
            offsetY: 0,
            inverseOrder: true,
            itemMargin: {
              horizontal: 0,
              vertical: 10,
            },
          },
          fill: {
            opacity: 1,
          },
          yaxis: { show: false },
        },
      });
    }
  }, [QuoteData, TotalEstRev, TotalActualCTC]);

  if (!chartState) return;

  return (
    <div className="CardDetails">
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
 
          height={250}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default QuoteSheetChart;
