import numeral from "numeral";
import React from "react";

const LabourHour = ({ Data }) => {
  return (
    <div className="LabourHour">
      <div className="LabourHourTracking CardOne">
        <div className="CardHead">Labour Hours Tracking</div>

        <div className="Details">
          <div className="Sec flex">
            <div className="cardsec">
              <div className="ItemHead">Labour Rev / H</div>
              <div className="ItemSub">{Data.labour_revenue_per_hour ? `$${numeral(Data.labour_revenue_per_hour).format(0,0)}` : "0"}</div>
            </div>
            <div className="cardsec">
              <div className="ItemHead">Labour Margin / H</div>
              <div className="ItemSub">{Data.labour_margin_per_hour ? `$${numeral(Data.labour_margin_per_hour).format(0,0)}` : "0"}</div>
            </div>
          </div>
          <div className="Sec flex">
            <div className="cardsec">
              <div className="ItemHead">Labour CTC / H</div>
              <div className="ItemSub">{Data.labour_ctc_per_hour ? `$${numeral(Data.labour_ctc_per_hour).format(0,0)}` : "0"}</div>
            </div>
            <div className="cardsec">
              <div className="ItemHead">Labour Gross Profit %</div>
              <div className="ItemSub">{Data.labour_gross_profit_percent ? Data.labour_gross_profit_percent : "0"}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="MetricsChart CardOne">
        <div className="CardHead">Chart</div>
      </div>
    </div>
  );
};

export default LabourHour;
