import numeral from "numeral";
import React from "react";

const Details = ({JobData}) => {

  
  return (
   
      <div className="CardDetails HoverCard">
        <div className="Head">
          <div className="CardHead">Job/Client Details</div>
        </div>
        <div className="Details">
          <div className="Sec flex">
            <div className="cardsec">
              <div className="ItemHead">Job number</div>
              <div className="ItemSub">{JobData && JobData.job_id ? JobData.job_id : "-"}</div>
            </div>
            <div className="cardsec">
              <div className="ItemHead">Client name</div>
              <div className="ItemSub">{JobData && JobData.client_firm ? JobData.client_firm : "-"}</div>
            </div>
          </div>
          <div className="Sec flex">
            <div className="cardsec" style={{width: "100%"}}>
              <div className="ItemHead">Site adress</div>
              <div className="ItemSub">{JobData && JobData.site_name ? JobData.site_name : "-"}</div>
            </div>      
          </div>
          
        </div>
      </div>
   
  );
};

export default Details;
