import numeral from 'numeral'
import React from 'react'

const KPIDetails = ({Data, TotalEstRev, JobData}) => {
  return (
    <div className="CardDetails HoverCard">
        <div className="Head">
          <div className="CardHead">Quote/Kpi Info</div>
        </div>
        <div className="Details">
          <div className="Sec flex">
            <div className="cardsec">
              <div className="ItemHead">Quote number</div>
              <div className="ItemSub">{Data && Data.quote_number ? Data.quote_number : "-"}</div>
            </div>
            <div className="cardsec">
              <div className="ItemHead">Contract Value</div>
              <div className="ItemSub">{TotalEstRev ? `$${numeral(TotalEstRev).format(0,0)}` : "-" }</div>
            </div>
          </div>
          <div className="Sec flex">
          <div className="cardsec">
              <div className="ItemHead">Labour c/o rate</div>
              <div className="ItemSub">{Data.labour_revenue_per_hour ? `$${numeral(Data.labour_revenue_per_hour).format(0,0)}` : "0"}</div>
            </div>
            <div className="cardsec">
              <div className="ItemHead">Labour cost rate</div>
              <div className="ItemSub">{Data.labour_ctc_per_hour ? `$${numeral(Data.labour_ctc_per_hour).format(0,0)}` : "0"}</div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default KPIDetails