import numeral from "numeral";
import React, { useState, useEffect } from "react";
import { QuotesList, UpdateQuotesList } from "../../reducers/AllApis";
import { useDispatch, useSelector } from "react-redux";

const QuoteSheetKpi = ({
  Data,
  TotalEstRev,
  TotalActualMargin,
  TotalEstMargin,
  TotalEstBudget,
  TotalActualCTC,
  TotalBudgetOverUnder,
  contractValueGpPer,
  LabourGPPer,
  ProductGPPer,
  PPEGPPer,
  SubConGPPer,
  OtherGPPer,
}) => {
  const [QuoteData, setQuoteData] = useState([]);
  const [focusedFields, setFocusedFields] = useState({});
  const [modifiedData, setModifiedData] = useState({});
  const dispatch = useDispatch();

  const { UpdateQuotesListSuccess } = useSelector((state) => state.allapi);
console.log("Data", Data.length);

  useEffect(() => {
    setQuoteData(Data);
    setModifiedData({
      quote_number: Data.quote_number,
    });
  }, [Data]);

  const FormDataMain = {
    ID: Data.quote_number,
    FormData: modifiedData,
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      dispatch(UpdateQuotesList(FormDataMain));
    }
  };

  const handleChange = (id, field, value) => {
    if (Data[field] !== value) {
      setModifiedData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setModifiedData((prevData) => {
        const newData = { ...prevData };
        delete newData[field];
        return newData;
      });
    }

    setQuoteData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFocus = (fieldName) => {
    setFocusedFields((prevState) => ({
      ...prevState,
      [fieldName]: true,
    }));
  };

  const handleBlur = (fieldName) => {
    setFocusedFields((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
  };

  useEffect(() => {
    if (UpdateQuotesListSuccess === true) {
      dispatch(QuotesList(QuoteData.quote_number));
    }
  }, [UpdateQuotesListSuccess]);
  return (
    <div className="QuoteSheetKpi CardOne">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Gross Profit %</th>
            <th>Est. Rev $</th>
            <th>Actual Margin $</th>
            <th>Est. Margin $</th>
            <th>Est. Budget $</th>
            <th>Actual $ CTC</th>
            <th>Budget (Over / U)</th>
          </tr>
        </thead>
        {Data ? (
          <tbody>
            <tr>
              <td>Contract Value</td>
              <td>
                {contractValueGpPer ? `${contractValueGpPer.toFixed(0)}%` : "0"}
              </td>
              <td> {TotalEstRev ? numeral(TotalEstRev).format("0,0") : "0"}</td>
              <td>
                {TotalActualMargin
                  ? numeral(TotalActualMargin).format("0,0")
                  : "0"}
              </td>
              <td>
                {TotalEstMargin ? numeral(TotalEstMargin).format("0,0") : "0"}
              </td>
              <td>
                {TotalEstBudget ? numeral(TotalEstBudget).format("0,0") : "0"}
              </td>
              <td>
                {TotalActualCTC ? numeral(TotalActualCTC).format("0,0") : "0"}
              </td>
              <td>
                {TotalBudgetOverUnder
                  ? `${TotalBudgetOverUnder.toFixed(0)}%`
                  : "0"}
              </td>
            </tr>
            <tr>
              <td>Labour $</td>
              <td>{LabourGPPer ? `${LabourGPPer.toFixed(0)}%` : "0"}</td>
              <td>
                {QuoteData && QuoteData.labour_est_revenue
                  ? numeral(QuoteData.labour_est_revenue).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={focusedFields.labour_actual_margin ? "number" : "text"}
                  id="labour_actual_margin"
                  name="labour_actual_margin"
                  value={
                    focusedFields.labour_actual_margin
                      ? QuoteData.labour_actual_margin || ""
                      : numeral(QuoteData.labour_actual_margin).format("0,0") ||
                        "0"
                  }
                  onFocus={() => handleFocus("labour_actual_margin")}
                  onBlur={() => handleBlur("labour_actual_margin")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "labour_actual_margin",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.labour_est_margin
                  ? numeral(QuoteData.labour_est_margin).format("0,0")
                  : "0"}
              </td>
              <td>
                {QuoteData && QuoteData.labour_est_budget
                  ? numeral(QuoteData.labour_est_budget).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={focusedFields.labour_actual_ctc ? "number" : "text"}
                  id="labour_actual_ctc"
                  name="labour_actual_ctc"
                  value={
                    focusedFields.labour_actual_ctc
                      ? QuoteData.labour_actual_ctc || ""
                      : numeral(QuoteData.labour_actual_ctc).format("0,0") ||
                        "0"
                  }
                  onFocus={() => handleFocus("labour_actual_ctc")}
                  onBlur={() => handleBlur("labour_actual_ctc")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "labour_actual_ctc",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.labour_budget_over_under
                  ? numeral(QuoteData.labour_budget_over_under).format("0,0")
                  : "0"}
              </td>
            </tr>
            <tr>
              <td>Product $</td>
              <td>{ProductGPPer ? `${ProductGPPer.toFixed(0)}%` : "0"}</td>
              <td>
                {QuoteData && QuoteData.product_est_revenue
                  ? numeral(QuoteData.product_est_revenue).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={focusedFields.product_actual_margin ? "number" : "text"}
                  id="product_actual_margin"
                  name="product_actual_margin"
                  value={
                    focusedFields.product_actual_margin
                      ? QuoteData.product_actual_margin || ""
                      : numeral(QuoteData.product_actual_margin).format(
                          "0,0"
                        ) || "0"
                  }
                  onFocus={() => handleFocus("product_actual_margin")}
                  onBlur={() => handleBlur("product_actual_margin")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "product_actual_margin",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.product_est_margin
                  ? numeral(QuoteData.product_est_margin).format("0,0")
                  : "0"}
              </td>
              <td>
                {QuoteData && QuoteData.product_est_budget
                  ? numeral(QuoteData.product_est_budget).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={focusedFields.product_actual_ctc ? "number" : "text"}
                  id="product_actual_ctc"
                  name="product_actual_ctc"
                  value={
                    focusedFields.product_actual_ctc
                      ? QuoteData.product_actual_ctc || ""
                      : numeral(QuoteData.product_actual_ctc).format("0,0") ||
                        "0"
                  }
                  onFocus={() => handleFocus("product_actual_ctc")}
                  onBlur={() => handleBlur("product_actual_ctc")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "product_actual_ctc",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.product_budget_over_under
                  ? numeral(QuoteData.product_budget_over_under).format("0,0")
                  : "0"}
              </td>
            </tr>
            <tr>
              <td>PP&E $</td>
              <td>{PPEGPPer ? `${PPEGPPer.toFixed(0)}%` : "0"}</td>
              <td>
                {QuoteData && QuoteData.plant_rental_and_equip_est_revenue
                  ? numeral(
                      QuoteData.plant_rental_and_equip_est_revenue
                    ).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.plant_rental_and_equip_actual_margin
                      ? "number"
                      : "text"
                  }
                  id="plant_rental_and_equip_actual_margin"
                  name="plant_rental_and_equip_actual_margin"
                  value={
                    focusedFields.plant_rental_and_equip_actual_margin
                      ? QuoteData.plant_rental_and_equip_actual_margin || ""
                      : numeral(
                          QuoteData.plant_rental_and_equip_actual_margin
                        ).format("0,0") || "0"
                  }
                  onFocus={() =>
                    handleFocus("plant_rental_and_equip_actual_margin")
                  }
                  onBlur={() =>
                    handleBlur("plant_rental_and_equip_actual_margin")
                  }
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "plant_rental_and_equip_actual_margin",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.plant_rental_and_equip_est_margin
                  ? numeral(QuoteData.plant_rental_and_equip_est_margin).format(
                      "0,0"
                    )
                  : "0"}
              </td>
              <td>
                {QuoteData && QuoteData.plant_rental_and_equip_est_budget
                  ? numeral(QuoteData.plant_rental_and_equip_est_budget).format(
                      "0,0"
                    )
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.plant_rental_and_equip_actual_ctc
                      ? "number"
                      : "text"
                  }
                  id="plant_rental_and_equip_actual_ctc"
                  name="plant_rental_and_equip_actual_ctc"
                  value={
                    focusedFields.plant_rental_and_equip_actual_ctc
                      ? QuoteData.plant_rental_and_equip_actual_ctc || ""
                      : numeral(
                          QuoteData.plant_rental_and_equip_actual_ctc
                        ).format("0,0") || "0"
                  }
                  onFocus={() =>
                    handleFocus("plant_rental_and_equip_actual_ctc")
                  }
                  onBlur={() => handleBlur("plant_rental_and_equip_actual_ctc")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "plant_rental_and_equip_actual_ctc",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.plant_rental_and_equip_budget_over_under
                  ? numeral(
                      QuoteData.plant_rental_and_equip_budget_over_under
                    ).format("0,0")
                  : "0"}
              </td>
            </tr>
            <tr>
              <td>Sub-Contractor $</td>
              <td>{SubConGPPer ? `${SubConGPPer.toFixed(0)}%` : "0"}</td>
              <td>
                {QuoteData && QuoteData.sub_contract_est_revenue
                  ? numeral(QuoteData.sub_contract_est_revenue).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.sub_contract_actual_margin ? "number" : "text"
                  }
                  id="sub_contract_actual_margin"
                  name="sub_contract_actual_margin"
                  value={
                    focusedFields.sub_contract_actual_margin
                      ? QuoteData.sub_contract_actual_margin || ""
                      : numeral(QuoteData.sub_contract_actual_margin).format(
                          "0,0"
                        ) || "0"
                  }
                  onFocus={() => handleFocus("sub_contract_actual_margin")}
                  onBlur={() => handleBlur("sub_contract_actual_margin")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "sub_contract_actual_margin",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.sub_contract_est_margin
                  ? numeral(QuoteData.sub_contract_est_margin).format("0,0")
                  : "0"}
              </td>
              <td>
                {QuoteData && QuoteData.sub_contract_est_budget
                  ? numeral(QuoteData.sub_contract_est_budget).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.sub_contract_actual_ctc ? "number" : "text"
                  }
                  id="sub_contract_actual_ctc"
                  name="sub_contract_actual_ctc"
                  value={
                    focusedFields.sub_contract_actual_ctc
                      ? QuoteData.sub_contract_actual_ctc || ""
                      : numeral(QuoteData.sub_contract_actual_ctc).format(
                          "0,0"
                        ) || "0"
                  }
                  onFocus={() => handleFocus("sub_contract_actual_ctc")}
                  onBlur={() => handleBlur("sub_contract_actual_ctc")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "sub_contract_actual_ctc",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.sub_contract_budget_over_under
                  ? numeral(QuoteData.sub_contract_budget_over_under).format(
                      "0,0"
                    )
                  : "0"}
              </td>
            </tr>
            <tr>
              <td>Other Charges</td>
              <td>{OtherGPPer ? `${OtherGPPer.toFixed(0)}%` : "0"}</td>
              <td>
                {QuoteData && QuoteData.other_charges_est_revenue
                  ? numeral(QuoteData.other_charges_est_revenue).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.other_charges_actual_margin
                      ? "number"
                      : "text"
                  }
                  id="other_charges_actual_margin"
                  name="other_charges_actual_margin"
                  value={
                    focusedFields.other_charges_actual_margin
                      ? QuoteData.other_charges_actual_margin || ""
                      : numeral(QuoteData.other_charges_actual_margin).format(
                          "0,0"
                        ) || "0"
                  }
                  onFocus={() => handleFocus("other_charges_actual_margin")}
                  onBlur={() => handleBlur("other_charges_actual_margin")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "other_charges_actual_margin",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.other_charges_est_margin
                  ? numeral(QuoteData.other_charges_est_margin).format("0,0")
                  : "0"}
              </td>
              <td>
                {QuoteData && QuoteData.other_charges_est_budget
                  ? numeral(QuoteData.other_charges_est_budget).format("0,0")
                  : "0"}
              </td>
              <td>
                <input
                  type={
                    focusedFields.other_charges_actual_ctc ? "number" : "text"
                  }
                  id="other_charges_actual_ctc"
                  name="other_charges_actual_ctc"
                  value={
                    focusedFields.other_charges_actual_ctc
                      ? QuoteData.other_charges_actual_ctc || ""
                      : numeral(QuoteData.other_charges_actual_ctc).format(
                          "0,0"
                        ) || "0"
                  }
                  onFocus={() => handleFocus("other_charges_actual_ctc")}
                  onBlur={() => handleBlur("other_charges_actual_ctc")}
                  onChange={(e) =>
                    handleChange(
                      QuoteData.id,
                      "other_charges_actual_ctc",
                      e.target.value
                    )
                  }
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </td>
              <td>
                {QuoteData && QuoteData.other_charges_budget_over_under
                  ? numeral(QuoteData.other_charges_budget_over_under).format(
                      "0,0"
                    )
                  : "0"}
              </td>
            </tr>
          </tbody>
        ) : (
          <div className="ItemSub" style={{ padding: "20px" }}>
            No Data
          </div>
        )}
      </table>
    </div>
  );
};

export default QuoteSheetKpi;
