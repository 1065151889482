import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { ListExcel, logoutAllApi, ReportCSV } from '../reducers/AllApis';
import { useNavigate } from 'react-router-dom';
import { logout, RefreshToken } from '../reducers/Auth';

const DefaultLayout = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(ListExcel())
      dispatch(ReportCSV())
    }, [])

    const initialRefreshToken = localStorage.getItem('refreshToken');
    const UserData = localStorage.getItem('UserData');

    const accessTokennew = localStorage.getItem('accessTokennew');
  
    const {SuccessUpdate, ReportDataErr, UpdateProfileError,ResetPassErr , ListErr, AddExcelErr, UpdateKpiErr, QuotesListDataErr,POorderListDataErr, GetProfileErr } = useSelector(
      (state) => state.allapi,
    );


    useEffect(() => {
      if (!initialRefreshToken) {
        navigate('/');
        window.location.reload();
      }
    }, [initialRefreshToken]);
  
    const Obj = {
      refresh: initialRefreshToken,
    };
    useEffect(() => {
      if (!UserData) {
        navigate('/');
      }
    }, [UserData]);
  
    function abc() {
      dispatch(RefreshToken(Obj));
    }
  
    const delayInMilliseconds = 36 * 1000;
    // const delayInMilliseconds =  6000;
    useEffect(() => {
      if (!accessTokennew) {
        setTimeout(abc, delayInMilliseconds);
      }
    }, [accessTokennew]);
  
    useEffect(() => {
      const unauthorizedError = 'Request failed with status code 401';
    
      if (
        ReportDataErr === unauthorizedError ||
        ListErr === unauthorizedError ||
        AddExcelErr === unauthorizedError ||
        UpdateKpiErr === unauthorizedError ||
        QuotesListDataErr === unauthorizedError ||
        POorderListDataErr === unauthorizedError ||
        GetProfileErr === unauthorizedError || 
        UpdateProfileError === unauthorizedError ||
        ResetPassErr === unauthorizedError
      ) {
        dispatch(logoutAllApi());
        dispatch(logout());
        navigate('/');
      }
    }, [ReportDataErr, ListErr, AddExcelErr, UpdateKpiErr, QuotesListDataErr, POorderListDataErr, GetProfileErr, UpdateProfileError, ResetPassErr]);




  return (
    <div className="bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="w-full DefaultCompOverflow relative flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10 MainComp">
              <div className="ml-4 PageHeading">{props.PageHead}</div>
              {props.children}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout