import React, { useState } from "react";
import ShowPass from "../../assets/images/Show-Pass.png";
import HidePass from "../../assets/images/Hide-Pass.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ResetPass = () => {
  const [ShowOldPassword, setShowOldPassword] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const [OldPass, setOldPass] = useState('');
  const [NewPass, setNewPass] = useState('');

  const handlePassSubmit = async (e) => {
    e.preventDefault();

    if (OldPass === '' || NewPass === '') {
      toast.error("Both fields are required!");
      return;
    }

    const formData = new FormData();
    formData.append('old_password', OldPass);
    formData.append('new_password', NewPass);

    const IdToken = localStorage.getItem('IdToken');

    const config = {
      headers: {
        Authorization: `Bearer ${IdToken}`,
      },
    };

    try {
      const res = await axios.put(
        'https://quotech-api.finsoeasy.com/api/auth/change-password/',
        formData,
        config,
      );

      if (res.data.detail) {
        toast.success(res.data.detail);
    
        setOldPass('');
        setNewPass('');
      }
    } catch (err) {
      if (err.response && err.response.data.old_password) {
        toast.error(err.response.data.old_password);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="ResetPass ProfileEditPage">
      <Toaster/>
      <div className="EditDet">
        <form onSubmit={handlePassSubmit}>
          <div className="FormSec">
            <div className="InputDiv">
              <div className="Label">Old Password</div>
              <input
                type={ShowOldPassword ? "text" : "password"}
                name="old_password"
                placeholder="Enter your old password"
                value={OldPass}
                onChange={(e) => setOldPass(e.target.value)} 
              />
              <span className="ShowPass">
                <img
                  src={ShowOldPassword ? HidePass : ShowPass}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowOldPassword(!ShowOldPassword)}
                  style={{ width: "30px" }}
                />
              </span>
            </div>

            <div className="InputDiv">
              <div className="Label">New Password</div>
              <input
                type={ShowNewPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter your new password"
                value={NewPass}
                onChange={(e) => setNewPass(e.target.value)} 
              />
              <span className="ShowPass">
                <img
                  src={ShowNewPassword ? HidePass : ShowPass}
                  alt="Toggle Password Visibility"
                  onClick={() => setShowNewPassword(!ShowNewPassword)}
                  style={{ width: "30px" }}
                />
              </span>
            </div>
          </div>

          <div className="FormSec justify-end">
            <button type="submit" className="ButtonPrimary">
              Submit
            </button>

            <button type="button" className="ButtonCancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
