import React, { useState } from "react";

const LabourUtil = ({ Data }) => {
  const [openRows, setOpenRows] = useState({});
  

  const handleToggle = (rowId) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };
  const paragraphStyles = {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const TableElHeight = {
    height: "60px",
  };

  const [columnGroups, setColumnGroups] = useState({
    group1: true,
    group2: true,
  });

  const toggleGroup = (group) => {
    setColumnGroups((prevGroups) => ({
      ...prevGroups,
      [group]: !prevGroups[group],
    }));
  };
  return (
    <div className="QuoteSheetKpi CardOne">
      
      <table>
        <thead>
        {/* <tr>
            <th></th>
            <th></th>
            <th className={`ItemLong  ${
              columnGroups.group1 ? 'Active' : 'NotActive'}`}>Descripton</th>
            <th>Product / System</th>
            <th>Target Labour Hours</th>
            <th>Target Labour Days</th>
            <th>Work Area</th>
          </tr> */}
          <tr>
            <th>Scope #</th>
            <th>Work Location</th>
            <th className="ItemLong">Descripton</th>
            <th>Product / System</th>
            <th>Target Labour Hours</th>
            <th>Target Labour Days</th>
            <th>Work Area</th>
          </tr>
        </thead>
        <tbody>
          {Data ?
            Data.map((x, i) => {
              return (
                <tr key={i} style={openRows[x.item] ? null : TableElHeight}>
                  <td>{x.item ? x.item : "-"}</td>
                  <td>{x.work_location ? x.work_location : "-"}</td>
                  <td>
                    <span style={openRows[x.item] ? null : paragraphStyles}>
                      {x.description ? x.description : "-"}
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleToggle(x.item)}
                    >
                      {openRows[x.item] ? "Read Less.." : "Read More.."}
                    </span>
                  </td>
                  <td>{x.product_or_system ? x.product_or_system : "-"}</td>
                  <td>{x.est_labour_hours ? x.est_labour_hours : "0"}</td>
                  <td>{x.est_labour_days ? x.est_labour_days : "0"}</td>
                  <td>{x.work_area ? x.work_area : "0"}</td>
                </tr>
              );
            }) : <div className="ItemSub" style={{padding: "20px"}}>No Data</div>}
        </tbody>
      </table>
    </div>
  );
};

export default LabourUtil;
