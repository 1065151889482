import React, { useEffect, useState, useRef } from "react";
import { useDispatch,useSelector } from "react-redux";
import { toggleTheme } from "../reducers/AllApis";
import UserOne from "../assets/images/user-icon.svg";
import moonlight from "../assets/images/moon-light.svg";
import sunlight from "../assets/images/sun icon.png"

const Header = (props) => {
  const [SearchTerm, setSearchTerm] = useState("");
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setSearchTerm("");
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  
  const { theme } = useSelector((state) => state.allapi);
  const handleThemeToggle = () => {
    console.log(theme);
    dispatch(toggleTheme());
  };

  
  const ProfileData = localStorage.getItem("ProfileData")
  const ProfileDataMain = ProfileData ? JSON.parse(ProfileData) : []

  const DateForm = (datenew) => {
    if (!datenew) {
      return ""; // Return an empty string or another default value if datenew is not provided
    }
  
    const date = new Date(datenew);
  
    const options = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Use 12-hour format
    };
  
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    return formattedDate;
  };

  return (
    <header className="justify-between HeaderMain sticky top-0 z-999 flex w-full " style={{height: "100px"}}>
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 ">
          <svg
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            style={{fill: "currentColor"}}
            className="c-pointer z-99999 block rounded-sm border  bg-white p-1.5 shadow-sm dark:bg-boxdark sidebarbtn"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="20px"
            height="20px"
          >
            <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
          </svg>
        </div>
      </div>
      {/* <div className="PagingHeader px-4 py-4">Dashboard</div> */}
      <div className="UtilsMain px-4 py-4">
        
      {/* <input className="" type="text" placeholder="Search.."/> */}
        
        <div>
          <div className="hidden text-right lg:block profiledtata">
                <div className="block text-sm font-medium text-black dark:text-white">
                  {ProfileDataMain.first_name ? ProfileDataMain.first_name : '-'} {ProfileDataMain.last_name ? ProfileDataMain.last_name : '-'} (Last Login:{ProfileDataMain.last_login ?  DateForm(ProfileDataMain.last_login)  : '-'})
                </div>
                <div className="block text-xs">{ProfileDataMain.email ? ProfileDataMain.email : '-'}</div>
                {/* <div className="block text-xs">Last login: {ProfileDataMain.last_login ?  DateForm(ProfileDataMain.last_login)  : '-'}</div> */}
          </div>
        </div>
        <div className="Profile headericons">
          
          <img src={UserOne} />
        </div>
        <div className="ThemeButton ">
          <div onClick={handleThemeToggle} className="headericons">
            <img style={{width: "20px"}} src={theme == "dark" ? sunlight : moonlight}/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
