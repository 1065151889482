import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListExcel, ResetTable } from "../reducers/AllApis";
import { DateForm } from "../utils/utils";

const Jobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const maxVisiblePages = 5;


  useEffect(() => {
dispatch(ResetTable())
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(ListExcel(searchTerm));
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch]);

  const handleNav = (data) => {
    navigate(`/jobs/${data.job_id}`, {
      state: { data: data.job_id },
    });
  };

  const { Listdata } = useSelector((state) => state.allapi);

  const totalItems = Listdata ? Listdata.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Listdata
    ? Listdata.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (startPage > 1) {
      pages.push(
        <button key={1} onClick={() => paginate(1)}>
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(<span key="start-ellipsis">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="end-ellipsis">...</span>);
      }
      pages.push(
        <button key={totalPages} onClick={() => paginate(totalPages)}>
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div>
      <div className="QuoteSheetKpi CardOne TableCard">
        <div className="CardHead">
          Job list
          <input
          className="SearchJobs"
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginLeft: "20px" }}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Job no.</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Sales person</th>
              <th>Site Name</th>
              <th>Tags</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((x, i) => {
                let formattedStatus =
                  x.status &&
                  x.status.charAt(0) + x.status.slice(1).toLowerCase();
                return (
                  <tr key={i} >
                    <td>{x.job_id ? x.job_id : "-"}</td>
                    <td>
                      {
                        x.start_date ? <DateForm datenew={x.start_date} /> : "-"
                      }
                      
                    </td>
                    <td>
                      {
                        x.end_date ? <DateForm datenew={x.end_date} /> : "-"
                      }
                      
                    </td>
                    <td>{x.sales_person ? x.sales_person : "-"}</td>
                    <td>{x.site_name ? x.site_name : "-"}</td>
                    <td>{x.tags.join(',')}</td>
                    <td>
                      <div
                        className={
                          x.status === "ACTIVE"
                            ? "ACTIVEtab"
                            : x.status === "INACTIVEtab"
                            ? "INACTIVE"
                            : ""
                        }
                      >
                        {formattedStatus}
                      </div>
                    </td>
                    <td>
                      <div className="View" onClick={() => handleNav(x)}>View</div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="JobPagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 1L2 6L7 11" stroke="#1814F3" stroke-width="1.5" />
          </svg>
          Previous
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
          <svg
          style={{transform: "rotate(180deg)"}}
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 1L2 6L7 11" stroke="#1814F3" stroke-width="1.5" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Jobs;
