import React, { useEffect, useState } from "react";
import Details from "../components/Jobs/Details";
import QuoteSheetChart from "../components/Jobs/QuoteSheetChart";
import { Box, CircularProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QuoteSheetKpi from "../components/Jobs/QuoteSheetKpi";
import LabourHour from "../components/Jobs/LabourHour";
import KPIDetails from "../components/Jobs/KPIDetails";
import LabourUtil from "../components/Jobs/LabourUtil";
import PrimaryQuoteSheet from "../components/Jobs/PrimaryQuoteSheet";
import LabourProgress from "../components/Jobs/LabourProgress";
import OtherInputTable from "../components/Jobs/OtherInputTable";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelUpload,
  QuotesList,
  ResetSuccessUpdate,
  UpdateKpi,
} from "../reducers/AllApis";
import toast, { Toaster } from "react-hot-toast";

const JobDetails = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  const dispatch = useDispatch();

  const QuoteId = location.state?.data;

  const [JobData, setJobData] = useState([]);
  const [SelectQuote, setSelectQuote] = useState("");
  const { QuotesListData, SuccessUpdate } = useSelector(
    (state) => state.allapi
  );
  const [IsUploaded, setIsUploaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [Canceled, isCanceled] = useState(false);

  const IdToken = localStorage.getItem("IdToken");

  const config = {
    headers: {
      Authorization: `Bearer ${IdToken}`,
    },
  };

  function ListTableDet() {
    axios
      .get(
        `https://quotech-api.finsoeasy.com/api/report/job/${QuoteId}`,
        config
      )
      .then((res) => {
        setJobData(res.data);
        setSelectQuote(res.data.quote[0]);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    ListTableDet();
  }, []);

  useEffect(() => {
    if (SelectQuote !== "") {
      dispatch(QuotesList(SelectQuote));
    }
  }, [SelectQuote]);

  const TotalEstRev =
    (QuotesListData && QuotesListData.labour_est_revenue
      ? QuotesListData.labour_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.product_est_revenue
      ? QuotesListData.product_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_revenue
      ? QuotesListData.plant_rental_and_equip_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_revenue
      ? QuotesListData.sub_contract_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_revenue
      ? QuotesListData.other_charges_est_revenue
      : 0);

  const TotalActualMargin =
    (QuotesListData && QuotesListData.labour_actual_margin
      ? QuotesListData.labour_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.product_actual_margin
      ? QuotesListData.product_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_actual_margin
      ? QuotesListData.plant_rental_and_equip_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_actual_margin
      ? QuotesListData.sub_contract_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.other_charges_actual_margin
      ? QuotesListData.other_charges_actual_margin
      : 0);

  const TotalEstMargin =
    (QuotesListData && QuotesListData.labour_est_margin
      ? QuotesListData.labour_est_margin
      : 0) +
    (QuotesListData && QuotesListData.product_est_margin
      ? QuotesListData.product_est_margin
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_budget
      ? QuotesListData.plant_rental_and_equip_est_budget
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_margin
      ? QuotesListData.sub_contract_est_margin
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_margin
      ? QuotesListData.other_charges_est_margin
      : 0);

  const TotalEstBudget =
    (QuotesListData && QuotesListData.labour_est_budget
      ? QuotesListData.labour_est_budget
      : 0) +
    (QuotesListData && QuotesListData.product_est_budget
      ? QuotesListData.product_est_budget
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_budget
      ? QuotesListData.plant_rental_and_equip_est_budget
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_budget
      ? QuotesListData.sub_contract_est_budget
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_budget
      ? QuotesListData.other_charges_est_budget
      : 0);

  const TotalActualCTC =
    (QuotesListData && QuotesListData.labour_actual_ctc
      ? QuotesListData.labour_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.product_actual_ctc
      ? QuotesListData.product_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_actual_ctc
      ? QuotesListData.plant_rental_and_equip_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_actual_ctc
      ? QuotesListData.sub_contract_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.other_charges_actual_ctc
      ? QuotesListData.other_charges_actual_ctc
      : 0);

  const TotalBudgetOverUnder =
    ((TotalActualCTC - TotalEstBudget) / TotalEstBudget) * 100;

  const contractValueGpPer =
    TotalEstMargin && TotalEstRev ? (TotalEstMargin / TotalEstRev) * 100 : 0;

  const LabourGPPer =
    QuotesListData &&
    QuotesListData.labour_est_margin &&
    QuotesListData.labour_est_revenue
      ? (QuotesListData.labour_est_margin / QuotesListData.labour_est_revenue) *
        100
      : 0;

  const ProductGPPer =
    QuotesListData &&
    QuotesListData.product_est_margin &&
    QuotesListData.product_est_revenue
      ? (QuotesListData.product_est_margin /
          QuotesListData.product_est_revenue) *
        100
      : 0;

  const PPEGPPer =
    QuotesListData &&
    QuotesListData.plant_rental_and_equip_est_margin &&
    QuotesListData.plant_rental_and_equip_est_revenue
      ? (QuotesListData.plant_rental_and_equip_est_margin /
          QuotesListData.plant_rental_and_equip_est_revenue) *
        100
      : 0;

  const SubConGPPer =
    QuotesListData &&
    QuotesListData.sub_contract_est_margin &&
    QuotesListData.sub_contract_est_revenue
      ? (QuotesListData.sub_contract_est_margin /
          QuotesListData.sub_contract_est_revenue) *
        100
      : 0;

  const OtherGPPer =
    QuotesListData &&
    QuotesListData.other_charges_est_margin &&
    QuotesListData.other_charges_est_revenue
      ? (QuotesListData.other_charges_est_margin /
          QuotesListData.other_charges_est_revenue) *
        100
      : 0;

  const handleUploadChange = (e) => {
    isCanceled(false);

    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("job_id", QuoteId);

      setIsLoading(true);
      dispatch(UpdateKpi(formData));
    }
  };

  const handleCancelButtonClick = () => {
    cancelUpload();
    isCanceled(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (SuccessUpdate === true) {
      ListTableDet();

      setIsLoading(false);
      setIsUploaded(true);
      setTimeout(() => {
        setIsUploaded(false);
        dispatch(ResetSuccessUpdate());
      }, 3000);
    } else if (SuccessUpdate === "Failed" && Canceled === false) {
      setIsLoading(false);
      setIsUploaded(false);
      console.log("something is wrong");

      toast.error("Something is wrong", {
        position: "top-center",
      });
      setTimeout(() => {
        dispatch(ResetSuccessUpdate());
      }, 3000);
    } else if (SuccessUpdate === "Failed" && Canceled === true) {
      setIsLoading(false);
      setIsUploaded(false);
      toast.error("Upload canceled", {
        position: "top-center",
      });
      setTimeout(() => {
        dispatch(ResetSuccessUpdate());
      }, 3000);
    }
  }, [SuccessUpdate]);

  return (
    <div className="JobsList MainPage">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="UploadSec">
        <div className="BackButton">
          <Link to="/jobs" style={{ zIndex: 999 }}>
            <p className="font-bold cursor-pointer">&larr; Back to the list</p>
          </Link>
        </div>
        <div className="UploadBar">
          <h4 className="text-xl font-semibold text-black dark:text-white flex UploadFileSec ">
            {isLoading === true && (
              <div className="flex gap-5 items-center" style={{ gap: "10px" }}>
                <CircularProgress size={20} className="CircularProgressBar" />

                <button
                  className=" CancelButton font-bold text-sm cursor-pointer inline-flex items-center justify-centerpy-2  text-center text-meta-5 hover:bg-opacity-90 "
                  onClick={handleCancelButtonClick}
                >
                  Cancel Uploading
                </button>
              </div>
            )}

            {isLoading === false && IsUploaded === false && (
              <div className="UploadFileSecSvg flex gap-2">
                <svg
                  className="w-6 h-6 "
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1552_21518)">
                    <path
                      d="M1.5 2C1.23478 2 0.98043 2.10536 0.792893 2.29289C0.605357 2.48043 0.5 2.73478 0.5 3V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3C13.5 2.73478 13.3946 2.48043 13.2071 2.29289C13.0196 2.10536 12.7652 2 12.5 2H10.5"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.5 0.5V3.5"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 0.5V3.5"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.5 2H8.5"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 7L7 5L5 7"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 5.25V10.5"
                      stroke="#1814f3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1552_21518">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Upload
              </div>
            )}
            {IsUploaded === true && (
              <div className="UploadFileSecSvg flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0,0,256,256"
                  width="30px"
                  height="30px"
                >
                  <g
                    fill="#1814f3"
                    fill-rule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M11,4c-3.89844,0 -7,3.10156 -7,7v28c0,3.89844 3.10156,7 7,7h28c3.89844,0 7,-3.10156 7,-7v-24l-2,2.3125v21.6875c0,2.80078 -2.19922,5 -5,5h-28c-2.80078,0 -5,-2.19922 -5,-5v-28c0,-2.80078 2.19922,-5 5,-5h26.40625l1.59375,-2zM43.25,7.75l-19.34375,22.8125l-8.125,-7.59375l-1.375,1.46875l8.90625,8.28125l0.78125,0.71875l0.65625,-0.78125l20,-23.625z"></path>
                    </g>
                  </g>
                </svg>
                Uploaded
              </div>
            )}
            {isLoading === false && (
              <input
                className="HiddenFile"
                style={{ cursor: "pointer", fontSize: "initial" }}
                type="file"
                onChange={handleUploadChange}
              />
            )}
          </h4>
        </div>
      </div>
      <div className="KPIDetailsJob">
        <div 
        // className="Section"
        >
          <Details JobData={JobData} />
        </div>

        <div 
        // className="Section"
        >
          <KPIDetails JobData={JobData} Data={QuotesListData} TotalEstRev={TotalEstRev} />
        </div>
        <div 
        // className="Section"
        >
          {QuotesListData && (
            <QuoteSheetChart
              QuoteData={QuotesListData}
              TotalEstRev={TotalEstRev}
              TotalActualCTC={TotalActualCTC}
            />
          )}
        </div>
      </div>
      <div className="KPITabs detailstablist">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                gap="10px"
                className="detailstablist"
              >
                <Tab
                  label="Quote Sheet KPI"
                  value="1"
                  sx={{ textTransform: "capitalize", mr: 3 }}
                />
                <Tab
                  label="Labour Utilisation Plan"
                  value="2"
                  sx={{ textTransform: "capitalize", mr: 3 }}
                />
                <Tab
                  label="Primary Quote Sheet"
                  value="3"
                  sx={{ textTransform: "capitalize", mr: 3 }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <QuoteSheetKpi
                Data={QuotesListData}
                TotalEstRev={TotalEstRev}
                TotalActualMargin={TotalActualMargin}
                TotalEstMargin={TotalEstMargin}
                TotalEstBudget={TotalEstBudget}
                TotalActualCTC={TotalActualCTC}
                TotalBudgetOverUnder={TotalBudgetOverUnder}
                contractValueGpPer={contractValueGpPer}
                LabourGPPer={LabourGPPer}
                ProductGPPer={ProductGPPer}
                PPEGPPer={PPEGPPer}
                SubConGPPer={SubConGPPer}
                OtherGPPer={OtherGPPer}
              />
            </TabPanel>
            <TabPanel value="2">
              <LabourUtil Data={QuotesListData.primary_quote} />
            </TabPanel>
            <TabPanel value="3">
              <PrimaryQuoteSheet Data={QuotesListData.primary_quote} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <div className="LabourHourSection">
        <LabourHour Data={QuotesListData} />
      </div>
      <div className="LabourProgressSection">
        <LabourProgress Data={QuotesListData} JobData={JobData} />
      </div>
      <div className="OtherInputTableSection">
        <OtherInputTable Data={QuotesListData} />
      </div>
    </div>
  );
};

export default JobDetails;
